
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// *****************
// Added for Foodis:
// *****************
@import 'ngx-toastr/toastr';



$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$foodis-primary-palette: (
        50: #F0F7E8,
        100: #DAEAC5,
        200: #C2DDA0,
        300: #A9CF7B,
        400: #97C55E,
        500: #4F8427,
        600: #76AC3A,
        700: #629830,
        800: #4F8427,
        900: #2D6216,
        A100: #84BB41,
        A200: #76AC3A,
        A400: #629830,
        A700: #2D6216,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$foodis-secondary-palette: (
        50: #f0fddf,
        100: #e8f5d7,
        200: #deebcd,
        300: #cddabd,
        400: #a9b599,
        500: #89957A,
        600: #616d53,
        700: #4E5941,
        800: #303B24,
        900: #111B00,
        A100: #cddabd,
        A200: #616d53,
        A400: #303B24,
        A700: #111B00,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

label {
  margin-bottom: 0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

// Style to fix the weird border with angular material for fs-input "outline" appearance style
.mdc-notched-outline__notch {
  border-right: none !important;
}

.mdc-button__label {
  display: flex;
}

.selected-item>span.mdc-list-item__primary-text {
  color: white;
}
.selected-item:hover>span.mdc-list-item__primary-text {
  color: #5E9925;
}

$account-primary: mat.define-palette($foodis-primary-palette);
$account-accent: mat.define-palette($foodis-secondary-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$account-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$account-theme: mat.define-light-theme((
  color: (
    primary: $account-primary,
    accent: $account-accent,
    warn: $account-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($account-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
